<template>
  <div class="pd-20 bg-white" style="background: #f2f2f2">
    <Row :gutter="50">
      <!-- <i-col span="6">
        <Menu width="auto" :active-name="activeMenu" @on-select="goToPage">
          <MenuGroup title="Basic">
            <MenuItem name="basic">
              Basic Settings
            </MenuItem>
          </MenuGroup>
          <MenuGroup title="Advance">
            <MenuItem name="advance">
              Advance Settings
            </MenuItem>
          </MenuGroup>
        </Menu>
      </i-col> -->

      <i-col span="24" >
        <router-view></router-view>
      </i-col>
    </Row>
  </div>
</template>

<script>
export default {
  created() {
    ["basic", "advance"].forEach(item => {
      if (this.$route.fullPath.includes(item)) {
        this.activeMenu = item;
      }
    });
  },
  data() {
    return {
      activeMenu: ""
    };
  },
  methods: {
    goToPage(name) {
      this.$router.push(`/user-profile/${name}`);
    }
  }
};
</script>

<style lang="less" scoped></style>
